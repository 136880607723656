import { User } from "../types";

export async function signIn(username: string, password: string): Promise<{ isOk: boolean; data?: User; error?: string }> {
  try {
    const response = await fetch('https://lio-2024.azurewebsites.net/api/Authentication/Authenticate', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ username, password }),
    });
    
    if (!response.ok) {
      throw new Error('Network response was not ok');
    }
    
    const token = await response.text();
    if (token) {
      const user: User = {
        token,
        username,
      };
      return { isOk: true, data: user };
    } else {
      return { isOk: false, error: 'Invalid token' };
    }
  } catch (error) {
    return { isOk: false, error: error instanceof Error ? error.message : 'Unknown error' };
  }
}

export async function getUser() {
  try {
    return { isOk: true, data: undefined };
  }
  catch {
    return {
      isOk: false
    };
  }
}

export async function createAccount(username: string, password: string) {
  try {
    console.log(username, password);
    return {
      isOk: true
    };
  }
  catch {
    return {
      isOk: false,
      message: "Failed to create account"
    };
  }
}

export async function changePassword(name: string, recoveryCode?: string) {
  try {
    console.log(name, recoveryCode);
    return {
      isOk: true
    };
  }
  catch {
    return {
      isOk: false,
      message: "Failed to change password"
    }
  }
}

export async function resetPassword(name: string) {
  try {
    console.log(name);
    return {
      isOk: true
    };
  }
  catch {
    return {
      isOk: false,
      message: "Failed to reset password"
    };
  }
}
