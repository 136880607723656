import "devextreme/data/odata/store";
import {
  Column,
  DataGrid,
  FilterRow,
  Scrolling,
  Editing,
  Paging,
  Pager,
} from "devextreme-react/data-grid";
import CustomStore from "devextreme/data/custom_store";
import { useEffect, useState } from "react";

interface TypeTiers {
  CodeType: string;
  NomType: string;
}

const Tiers = () => {
  const [typeTiersData, setTypeTiersData] = useState<TypeTiers[]>([]);
  const tokenData = sessionStorage.getItem("tokenData");

  useEffect(() => {
    const fetchTypeTiersData = async () => {
      try {
        const response = await fetch(
          "https://lio-2024.azurewebsites.net/api/odata/ZsysType",
          {
            headers: {
              Authorization: `Bearer ${tokenData}`,
            },
          }
        );
        if (response.ok) {
          const data = await response.json();
          setTypeTiersData(data.value);
        } else {
          console.error("Erreur de chargement des types:", response.statusText);
        }
      } catch (error) {
        console.error("Erreur lors de la récupération des types:", error);
      }
    };

    fetchTypeTiersData();
  }, [tokenData]);

  const transformFilter = (filter: any): string => {
    if (Array.isArray(filter)) {
      if (typeof filter[0] === "string" && filter.length === 3) {
        const [field, operator, value] = filter;
        
        // Ajouter les différentes colonnes 
        const isNumericField = ['IdCategorieTiers', 'IdCategorieComptable', 'CodeEtatTiers'].includes(field);

        switch (operator) {
          case "contains":
            return `contains(${field},'${value}')`;
          case "=":
            return `${field} eq ${isNumericField ? value : `'${value}'`}`;
          case "<>":
            return `${field} ne ${isNumericField ? value : `'${value}'`}`; 
          case ">":
            return `${field} gt  ${isNumericField ? value : `'${value}'`}`;
          case ">=":
            return `${field} ge  ${isNumericField ? value : `'${value}'`}`;
          case "<":
            return `${field} lt  ${isNumericField ? value : `'${value}'`}`;
          case "<=":
            return `${field} le  ${isNumericField ? value : `'${value}'`}`;
          default:
            return `${field} ${operator}  ${isNumericField ? value : `'${value}'`}`;
        }
      }

      const subFilters = filter
        .map((subFilter) => transformFilter(subFilter))
        .filter(Boolean);
      if (subFilters.length > 1) {
        return `(${subFilters.join(" and ")})`;
      }
      return subFilters[0];
    }
    return "";
  };

  const dataSource = new CustomStore({
    key: "Oid",
    load: async (loadOptions) => {
      const params = [];
      let totalCount = 0;

      try {
        const countResponse = await fetch(
          `https://lio-2024.azurewebsites.net/api/odata/Tier?$count=true&$top=0`,
          {
            headers: {
              Authorization: `Bearer ${tokenData}`,
            },
          }
        );
        if (countResponse.ok) {
          const countData = await countResponse.json();
          totalCount = countData["@odata.count"];
        } else {
          const errorText = await countResponse.text();
          throw new Error(
            `Erreur de chargement du compte : ${countResponse.status} - ${countResponse.statusText}: ${errorText}`
          );
        }
      } catch (err) {
        console.error(err);
        throw err;
      }

      if (loadOptions.sort && Array.isArray(loadOptions.sort)) {
        const sort = loadOptions.sort
          .map((s) => {
            if (typeof s === "object" && s.selector) {
              return `${s.selector} ${s.desc ? "desc" : "asc"}`;
            }
            return "";
          })
          .filter(Boolean)
          .join(",");
        if (sort) {
          params.push(`$orderby=${sort}`);
        }
      }

      // Filtre
      if (loadOptions.filter) {
        const filter = transformFilter(loadOptions.filter);
        if (filter) {
          params.push(`$filter=${filter}`);
        }
      }

      // Pagination
      if (loadOptions.skip !== undefined) {
        params.push(`$skip=${loadOptions.skip}`);
      }
      if (loadOptions.take !== undefined) {
        params.push(`$top=${loadOptions.take}`);
      }

      const queryStringParams = params.length > 0 ? `?${params.join("&")}` : "";

      try {
        const response = await fetch(
          `https://lio-2024.azurewebsites.net/api/odata/Tier${queryStringParams}`,
          {
            headers: {
              Authorization: `Bearer ${tokenData}`,
            },
          }
        );
        if (response.ok) {
          const data = await response.json();
          return {
            data: data.value,
            totalCount: totalCount,
          };
        } else {
          const errorText = await response.text();
          throw new Error(
            `Erreur de chargement des données : ${response.status} - ${response.statusText}: ${errorText}`
          );
        }
      } catch (err) {
        console.error(err);
        throw err;
      }
    },

    update: async (key, values) => {
      try {
        const response = await fetch(
          `https://lio-2024.azurewebsites.net/api/odata/Tier(${key})`,
          {
            method: "PATCH",
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${tokenData}`,
            },
            body: JSON.stringify(values),
          }
        );
        if (!response.ok) {
          throw new Error("Erreur de Update");
        }
      } catch (err) {
        throw err;
      }
    },
    remove: async (key) => {
      try {
        const response = await fetch(
          `https://lio-2024.azurewebsites.net/api/odata/Tier(${key})`,
          {
            method: "DELETE",
            headers: {
              Authorization: `Bearer ${tokenData}`,
            },
          }
        );

        if (!response.ok) {
          const errorText = await response.text();
          throw new Error(
            `Erreur suppression : ${response.status} - ${response.statusText}: ${errorText}`
          );
        }
      } catch (err) {
        console.error(err);
        throw err;
      }
    },
    insert: async (values) => {
      try {
        const response = await fetch(
          "https://lio-2024.azurewebsites.net/api/odata/Tier",
          {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${tokenData}`,
            },
            body: JSON.stringify(values),
          }
        );
        if (!response.ok) {
          const errorText = await response.text();
          throw new Error(
            `Erreur d'insertion : ${response.status} - ${response.statusText}: ${errorText}`
          );
        }
      } catch (err) {
        throw err;
      }
    },
  });

  const getNomTypeById = (typeTiersId: any) => {
    const typeTiers = typeTiersData.find(
      (type) => type.CodeType === typeTiersId
    );
    return typeTiers ? typeTiers.NomType : "";
  };

  return (
    <DataGrid
      dataSource={dataSource}
      showBorders={true}
      width="100%"
      height={600}
      remoteOperations={true}
    >
      <FilterRow visible={true} />
      <Scrolling mode="standard" />
      <Editing
        mode="row"
        allowAdding={true}
        allowDeleting={true}
        allowUpdating={true}
      />
      <Paging defaultPageSize={5} />
      <Pager
        visible={true}
        showPageSizeSelector={true}
        allowedPageSizes={[5, 10, 20]}
        displayMode="full"
        showInfo={true}
        showNavigationButtons={true}
      />

      <Column dataField="RefTiers" caption="Ref Tiers" />
      <Column
        dataField="TypeTiers"
        caption="TypeTiers"
        calculateDisplayValue={(rowData) => getNomTypeById(rowData.TypeTiers)}
        lookup={{
          dataSource: typeTiersData,
          valueExpr: "CodeType",
          displayExpr: "NomType",
        }}
        allowSorting={false}
        allowFiltering={false}
        allowEditing={true}
      />
      <Column dataField="IdCategorieTiers" caption="IdCategorieTiers" dataType="number" />
      <Column dataField="IdCategorieComptable" caption="IdCategorieComptable" />
      <Column dataField="RaisonSociale" caption="Raison Sociale" />
      <Column dataField="NomAbrege" caption="Nom Abrege" />
      <Column dataField="CodeEtatTiers" caption="CodeEtatTiers" />
    </DataGrid>
  );
};

export default Tiers;