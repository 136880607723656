import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import './LoginForm.scss';
import { useAuth } from "../../contexts/auth";

export default function LoginForm() {

    const [formData, setFormData] = useState({
        username: '',
        password: '',
    });

    const navigate = useNavigate();
    const [errorMessage, setErrorMessage] = useState("");
    const [loading, setLoading] = useState(false);
    const { signIn } = useAuth();
    const tokenData = sessionStorage.getItem('tokenData');

    // useEffect(() => {
    //     if(tokenData) {
    //         navigate("/home");
    //     }
    // }, [navigate]);

    const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const { id, value } = e.target;
        setFormData((prevFormData) => ({
            ...prevFormData,
            [id]: value,
        }));
    };

    const handleLogin = async (e: React.FormEvent) => {
        e.preventDefault();
        if (formData.username === "") {
            setErrorMessage("Veuillez remplir le champ username");
            return;
        }

        setLoading(true);
        setErrorMessage("");
        try {
            const result = await signIn(formData.username, formData.password);
            if (result.isOk) {
                const token = result.data?.token;
                if (token) {
                    sessionStorage.setItem('tokenData', token);
                    navigate("/home");
                } else {
                    setErrorMessage('Token is undefined');
                }
            } else {
                setErrorMessage('Nom d\'utilisateur ou mot de passe incorrect !');
            }
        } catch (error) {
            setErrorMessage('Erreur lors de la récupération du jeton');
        } finally {
            setLoading(false);
        }
    };

    return (
        <div>
            <form onSubmit={handleLogin}>
                <div>
                    <label htmlFor="username">Nom d'utilisateur :</label>
                    <input type="text" id="username" value={formData.username} onChange={handleInputChange} />
                </div>
                <div>
                    <label htmlFor="password">Mot de passe :</label>
                    <input type="password" id="password" value={formData.password} onChange={handleInputChange} />
                </div>

                {errorMessage && <p className="error">{errorMessage}</p>}
                <button type="submit" disabled={loading}>
                    {loading ? "Connexion en cours..." : "Se connecter"}
                </button>
            </form>
        </div>
    );
}
